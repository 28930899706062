@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.cards-container {
    height: 30vh;
    width: 20vh;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    box-shadow: -0.3vh 0.3vh 0 #000, 0.3vh 0.3vh 0 #000, 0.3vh -0.3vh 0 #000,
        -0.3vh -0.3vh 0 #000;
    padding: 0.5vh;
    border: 0.7vh solid transparent;
    border-radius: 2vh;
    margin-bottom: 2vh;
}
.card-score {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.pokemon-score {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24vh;
    height: 3vh;
    border: 0.3vh rgb(146, 138, 138) solid;
    border-radius: 1vh;
    font-size: 0.8vh;
}
.card-top {
    height: 2vh;
    padding: 0.2vh 0;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-bottom {
    height: 12vh;
    width: 90%;
    padding-top: 1vh;
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}
.img-container {
    height: 12vh;
    width: 18vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    border: 0.3vh solid rgb(21, 21, 21);
    border-radius: 1vh;
}

.pokemon-img {
    height: 100%;
    width: auto;
}

.card-name {
    height: 1vh;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vh;
}

.type {
    background-color: #c0c0c0;
    border-radius: 0.8vh;
    border: solid 0.2vh black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5vh;
    font-weight: bold;
    height: 2vh;
    color: white;
    text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
        -1px -1px 0 #000;
}

.move-row .type {
    width: 40%;
    padding: 0vh 0.4vh;
}

.type-container .type {
    padding: 0 0.4vh;
    /* margin-left: 0.5em; */
    font-size: 0.6vh;
}

.type-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
}
@media (max-width: 600px), (max-height: 800px) {
    .card-score {
        width: 100%;
        flex-direction: column;
    }
    .cards-container {
        width: 15rem;
        max-width: 90%;
        height: 8rem;
        padding: 0.2rem;
        border: 0.2rem solid transparent;
        border-radius: 0.7rem;
        box-shadow: -0.2rem 0.2rem 0 #000, 0.2rem 0.2rem 0 #000,
            0.2rem -0.2rem 0 #000, -0.2rem -0.2rem 0 #000;
    }
    .card-bottom {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
    }
    .pokemon-score {
        margin-top: 1rem;
        width: 8rem;
        font-size: 0.5rem;
        height: 1rem;
        border: 0.2rem rgb(146, 138, 138) solid;
    }
    .card-top {
        height: fit-content;
        width: 100%;
    }
    .card-name {
        font-size: 0.6rem;
        font-weight: bold;
        height: 0.5rem;
        margin-left: 0.5rem;
    }
    .type-container .type {
        height: 0.6rem;
        font-size: 0.4rem;
    }
    .type {
        height: 0.4rem;
        width: fit-content;
        border: 1px solid black;
        font-size: 0.3rem;
        padding: 0.1rem;
    }
    .type-container {
        font-size: 0.2rem;
    }
    .type-text {
        font-size: 0.4rem;
        padding: 0.1rem;
    }
    .img-container {
        height: 5rem;
        width: 5rem;
    }
    .move-name {
        width: 100%;
        font-size: 0.4rem;
        margin: 0;
        text-align: left;
        margin-left: 1rem;
    }
    .move-row .type {
        width: fit-content;
    }
}
