@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

.pokedex-container {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 10vh;
    flex-grow: 1;
    justify-content: center;
    overflow-y: scroll;
    gap: 1rem;
}

.pokecard {
    height: 17rem;
    width: 13rem;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0.5rem;
    border-radius: 1rem;
    border: solid 5px;
    background-size: cover;
    padding: 2rem 0.5rem 0.5rem 0.5rem;
}

body {
    font: 1em sans-serif;
    text-align: center;
}
.pokemon-image {
    align-self: flex-end;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    height: auto;
    margin: 0 auto 0 auto;
    transform-origin: bottom;
    width: 200px;
}
.bounce-1 {
    animation-name: bounce-1;
    animation-timing-function: linear;
}
@keyframes bounce-1 {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.pokemon-name,
.pokemon-id {
    margin: 1%;
    font-family: "Press Start 2P";
    font-size: 0.8em;
}

.pokemon-type {
    font-family: "Press Start 2P";
    font-size: 0.6em;
}

.pokedex-text-box {
    background-color: rgba(237, 237, 237, 0.5);
    border-radius: 10px;
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
}
@media (max-width: 600px) {
    .pokedex-container {
        gap: 0.2rem;
    }
    .pokecard {
        width: 9rem;
        height: 13rem;
        padding: 0.3rem;
    }
    .pokemon-image {
        margin-top: 1rem;
        width: 9rem;
    }
    .pokedex-text-box {
        width: 100%;
        padding: 0.5rem 0.3rem;
    }
    .pokemon-name {
        font-size: 0.6rem;
    }
    .pokemon-type {
        font-size: 0.4rem;
        line-height: 0.5rem;
    }
}
