@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    font-family: "Press Start 2P";
}

.battle-container {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;

    height: fit-content;
    justify-content: center;
    gap: 1vw;
    align-items: center;
}
.details-container {
    flex-shrink: 0;
    height: 20vh;

    display: flex;
    justify-content: center;
    align-items: center;
}
.results-container {
    flex-shrink: 0;

    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
@media (max-width: 600px) {
    .battle-container,
    .details-container,
    .results-container {
        width: 100vw;
        height: fit-content;
    }
    .battle-container {
        flex-direction: column;
        padding-top: 1rem;
    }
    .vs {
        font-size: 0.8rem;
    }
    .main {
        position: relative;
    }
    .battle-page {
        height: fit-content;
        /* border: 3px solid red; */
        overflow-y: scroll;
    }
}
