@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
html,
body {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    overscroll-behavior: none;
}
.App {
    text-align: center;
    height: 100vh;
    font-family: "Press Start 2P";
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.App-header {
    /* max-height: 9vh; */
    z-index: 5;
    height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1vh;
    width: 100vw;
    box-sizing: border-box;
}

.page-title {
    background-color: #da1b1b;
    padding: 1.5vh 0.5vh;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-bottom: black solid 8px;
    font-size: 2vh;
    line-height: 2.5vh;
}

a {
    text-decoration: none;
}

#title-link {
    width: 100%;
    height: fit-content;
}
.home .title {
    line-height: 2rem;
}
main {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 10vh;
    /* padding: 1rem; */
    flex-grow: 1;
}

footer {
    height: fit-content;
    width: 100%;
    font-size: 10px;
    color: gray;
}
footer p {
    padding: 0rem 2rem;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

@media (max-width: 600px) {
    .App-header {
        height: fit-content;
    }
    .page-title {
        font-size: 1rem;
        line-height: 1.3rem;
        padding: 0.5rem;
    }
    .home {
        padding: 0.5rem;
    }
    .home .title {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .home .gif {
        height: 8rem;
        margin: 0.5rem;
    }
    .home .credits {
        font-size: 0.6rem;
    }
    footer {
        font-size: 0.4rem;
    }
    footer p {
        padding: 0rem 1rem;
    }
    main {
        width: 100%;
    }
}
