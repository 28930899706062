@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.moves-container {
    height: 10vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: space-around;
}

.move-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* font-family: 'Montserrat', sans-serif; */
    text-align: left;
    font-size: 0.7vh;
    width: 100%;
    font-weight: bold;
}

.move-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
