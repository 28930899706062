.nav-container {
    display: flex;
    text-decoration: none;
    justify-content: space-around;
    width: 100vw;
    font-size: 1vh;
    background-color: white;
    height: fit-content;
    border-bottom: 0.4vh solid black;
    box-sizing: border-box;
}
.link {
    text-decoration: underline;
    color: black;
}

a:hover {
    text-shadow: 4px 4px 10px black;
}

@media (max-width: 600px) {
    .link {
        font-size: 0.7rem;
    }
}
