.detail-wrap {
    width: 100%;
    height: 14vh;
    margin: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 2vh;
    font-size: 0.85vh;
    color: white;
    text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
        -1px -1px 0 #000;
    box-shadow: 0 0 0 0.6vh black;
    outline: 0.4vh solid white;
    border: 0.2vh solid black;
    box-sizing: border-box;
}
.info-row {
    min-width: fit-content;
    width: 300px;
    display: flex;
    justify-content: space-between;
}
@media (max-width: 600px) {
    .detail-wrap {
        margin: 1rem;
        padding: 1rem;
        height: fit-content;
        box-sizing: border-box;
    }
    .info-row {
        width: 100%;
        font-size: 0.5rem;
    }
    .selected-move {
        font-size: 1rem;
    }
}
