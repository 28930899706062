button {
    width: 50%;
    min-width: 300px;
    height: 20%;
    border: 0.5vh solid rgb(146, 138, 138);
    border-radius: 1.5vh;
    font-size: 1.5vh;
    font-family: "Press Start 2P";
    background-color: white;
}
.winner-img-div {
    overflow: visible;
    height: 17vh;
    width: 17vh;
    background-size: cover;
    background-position: center;
    animation: 1s linear 0s infinite alternate jump;
}
.text-result {
    text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
        -1px -1px 0 #000;
    letter-spacing: 0.2em;
    font-size: 2vh;
}
.sparkles1 {
    width: 4vh;
    height: auto;
    z-index: -1;
    transform: translateY(4vh);
}
.sparkles2 {
    width: 6vh;
    height: auto;
    z-index: -1;
    transform: translateY(-3vh);
}
.winner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

@keyframes jump {
    0% {
        transform: translateX(-2vh) translateY(2vh);
    }
    40% {
        transform: translateX(-0.4vh) translateY(-2vh);
    }
    60% {
        transform: translateX(0.4vh) translateY(-2vh);
    }
    100% {
        transform: translateX(2vh) translateY(2vh);
    }
}
@media (max-width: 600px) {
    button {
        padding: 0.5rem 1rem;
        margin: 1rem;
        font-size: 0.6rem;
        width: fit-content;
        min-width: fit-content;
    }
}
